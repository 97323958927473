// Function to calculate Levenshtein distance between two strings
function levenshteinDistance(word1, word2) {
  const dp = [];

  for (let i = 0; i <= word1.length; i++) {
    dp[i] = [i];
  }

  for (let j = 0; j <= word2.length; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= word1.length; i++) {
    for (let j = 1; j <= word2.length; j++) {
      const cost = word1[i - 1] === word2[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1,
        dp[i][j - 1] + 1,
        dp[i - 1][j - 1] + cost,
      );
    }
  }

  return dp[word1.length][word2.length];
}

function match(movieName, guess) {
  // Convert both inputs to strings
  movieName = String(movieName);
  guess = String(guess);

  // Convert hyphens and underscores to spaces
  movieName = movieName.replace(/[-_]/g, " ");
  guess = guess.replace(/[-_]/g, " ");

  // Remove all punctuation from movieName and guess
  movieName = movieName.replace(/[^\w\s]/g, "");
  guess = guess.replace(/[^\w\s]/g, "");

  // Convert both strings to lowercase for case insensitivity
  movieName = movieName.toLowerCase();
  guess = guess.toLowerCase();

  // Split both strings into arrays of words
  const movieWords = movieName.split(" ");
  const guessWords = guess.split(" ");

  // Initialize edit distance count
  let editDistance = 0;

  // If the movie title has 2 or fewer words, require a full match
  if (movieWords.length <= 2) {
    return levenshteinDistance(movieName, guess) <= 2;
  }

  // Check if at least 2 words from the guess are within an edit distance of 2 from the movie name
  for (let i = 0; i < guessWords.length; i++) {
    for (let j = 0; j < movieWords.length; j++) {
      // Calculate edit distance between the current guess word and movie word
      let distance = levenshteinDistance(guessWords[i], movieWords[j]);

      // If edit distance is less than or equal to 2, consider it a match
      if (distance <= 2) {
        editDistance++;
        break; // Move to the next guess word
      }
    }
  }

  // Return true if at least 2 words from guess are within an edit distance of 2 from the movie name
  return editDistance >= 2;
}

export default match;
