import React, { createContext, useState, useContext } from "react";

// Define a default value for the context
const defaultContextValue = {
  curtainsOpen: true,
  openCurtains: () => {},
  closeCurtains: () => {},
};

const CurtainContext = createContext(defaultContextValue);

export const CurtainProvider = ({ children }) => {
  const [curtainsOpen, setCurtainsOpen] = useState(false);

  console.log("!", curtainsOpen);

  const openCurtains = () => {
    console.log("!!!!CALLED");
    setCurtainsOpen(true);
  };
  const closeCurtains = () => {
    console.log("curtains!", curtainsOpen);
    setCurtainsOpen(false);
  };

  return (
    <CurtainContext.Provider
      value={{ curtainsOpen, openCurtains, closeCurtains }}
    >
      {children}
    </CurtainContext.Provider>
  );
};

export const useCurtain = () => useContext(CurtainContext);
