import { useCurtain } from "./CurtainContext";
import "./Stage.css";

const Stage = ({ children }) => {
  const { curtainsOpen } = useCurtain();

  const handleOnAnimationStart = (e) => {};

  const handleOnAnimationEnd = (e) => {};

  return (
    <div className="stageWrapper">
      <div className={curtainsOpen ? "curtainsOpen" : "curtainsClosed"}>
        <div
          className={`curtain-left curtain curtainOpening`}
          onAnimationStart={handleOnAnimationStart}
          onAnimationEnd={handleOnAnimationEnd}
        ></div>

        <div className="curtain-right curtain"></div>
        <div className="balance"></div>
        <div className="stageContent">{children}</div>
      </div>
    </div>
  );
};

export default Stage;
