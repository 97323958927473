import React, { useState } from "react";
import useFetchMovies from "./hooks/useFetchMovies";
import "./SelectScreen.css";

const genres = [
  "Drama",
  "Comedy",
  "Action",
  "Crime",
  "Adventure",
  "Romance",
  "Thriller",
  "Animation",
];

const genreMapping = {
  Drama: "🎭 Drama",
  Comedy: "😂 Comedy",
  Action: "💥 Action",
  Crime: "🕵️‍♂️ Crime",
  Adventure: "🏔️ Adventure",
  Romance: "❤️ Romance",
  Thriller: "😱 Thriller",
  Animation: "🎨 Animation",
};

const decades = [1980, 1990, 2000, 2010, 2020];

function DecadeSelectMessage({ selectedDecades }) {
  console.log("selectedDecades", selectedDecades);

  if (selectedDecades.length === 0 || selectedDecades.length == 5) {
    return (
      <p>
        Dive into a cinematic journey spanning the past 5 decades! Guess the top
        movies from each era and prove your film expertise.
      </p>
    );
  } else if (selectedDecades.length === 1) {
    const decade = selectedDecades[0];
    switch (decade) {
      case 1980:
        return (
          <p>
            Grab your leg warmers and get ready to moonwalk down memory lane!
            Let's play with the most tubular movies of the 80s!
          </p>
        );
      case 1990:
        return (
          <p>
            Dust off your Tamagotchi and put on your best flannel, because we're
            diving into the rad 90s movie scene!
          </p>
        );
      case 2000:
        return (
          <p>
            Frosted tips and flip phones at the ready! Get set to guess the most
            iconic movies of the 2000s!
          </p>
        );
      case 2010:
        return (
          <p>
            Avengers assemble! It's time to test your knowledge of the
            blockbusters and hidden gems from the 2010s!
          </p>
        );
      case 2020:
        return (
          <p>
            From lockdown to lights, camera, action! Let's explore the latest
            and greatest movies of the 2020s!
          </p>
        );
      default:
        return null;
    }
  } else {
    const decadeList = selectedDecades
      .map((decade) => decade.toString())
      .join(", ");
    return (
      <p>
        Buckle up for a thrilling ride through the top movies of the selected
        decades: {decadeList}
      </p>
    );
  }
}

function SelectScreen({ setMovies, startGame }) {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedDecades, setSelectedDecades] = useState([]);
  const { fetchMovies } = useFetchMovies();

  const toggleGenre = (genre) => {
    if (selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.filter((x) => x !== genre));
    } else {
      setSelectedGenres([...selectedGenres, genre]);
    }
  };

  const toggleDecade = (decade) => {
    if (selectedDecades.includes(decade)) {
      setSelectedDecades(selectedDecades.filter((x) => x !== decade));
    } else {
      setSelectedDecades([...selectedDecades, decade]);
    }
  };

  const wantsToStartGame = async () => {
    const movies = await fetchMovies(selectedGenres, selectedDecades);
    setMovies(movies);
    startGame();
  };

  return (
    <div className="selectScreen">
      <h1>9️⃣emoji</h1>
      <p>
        In this brain-teasing game, you'll be presented with 9 emojis that
        represent a movie title. Your task is to crack the code and type in the
        name of the film. Test your movie knowledge and see how many you can
        guess correctly!
      </p>
      <div>
        <div className="decades">
          <h2>Select decades.</h2>
          {decades.map((decade) => (
            <div
              key={decade}
              className={`pill ${
                selectedDecades.includes(decade) ? "selected" : ""
              }`}
              onClick={() => toggleDecade(decade)}
            >
              {decade}
            </div>
          ))}
          <DecadeSelectMessage selectedDecades={selectedDecades} />
        </div>
        <div className="genres">
          <h2>Select genres.</h2>
          {genres.map((genre) => (
            <div
              key={genre}
              className={`pill ${
                selectedGenres.includes(genre) ? "selected" : ""
              }`}
              onClick={() => toggleGenre(genre)}
            >
              {genreMapping[genre]}
            </div>
          ))}
        </div>
      </div>
      <button onClick={wantsToStartGame}>Play</button>
    </div>
  );
}

export default SelectScreen;
