import useMovieGuesser from "./useMovieGuesser";
import Hints from "./Hints";
import "./Guesser.css";
import { useCurtain } from "./CurtainContext";
import { useEffect, useState } from "react";
import EmojiDisplayer from "./EmojiDisplayer";
import GraphemeSplitter from "grapheme-splitter";

const MovieGuesserInput = ({
  guessText,
  setGuessText,
  guessMovie,
  feedback,
}) => {
  const handleInputChange = (event) => {
    setGuessText(event.target.value);
  };

  useEffect(() => {
    setGuessText("");
  }, [feedback]);

  const processKeydown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      guessMovie(e.target.value);
    }
  };

  return (
    <div className="movieGuesserInputWrapper">
      <input
        placeholder="Which movie is this?"
        className="movieGuesserInput"
        value={guessText}
        onChange={handleInputChange}
        onKeyDown={processKeydown}
      />
    </div>
  );
};

const FeedbackContainer = ({ feedback, setFeedback, setIsCaptive }) => {
  const handleAnimationEnd = () => {
    if (feedback != "correct") {
      setFeedback(null);
      setIsCaptive(false);
    }
  };

  return (
    <div className="feedbackContainer">
      {feedback && (
        <div
          className={`feedback ${feedback}`}
          onAnimationEnd={handleAnimationEnd}
        >
          {feedback === "correct" ? "✅" : "❌"}
        </div>
      )}
    </div>
  );
};

const Guesser = ({ movies, initialLives, quitGame }) => {
  const {
    movie,
    guessMovie,
    lives,
    gameEnded,
    feedback,
    setFeedback,
    chooseRandomMovie,
    guessedCorrectly,
    skipMovie,
    setSkipped,
    skipped,
    isCaptive,
    setIsCaptive,
  } = useMovieGuesser(movies, initialLives);

  const { openCurtains, closeCurtains } = useCurtain();

  const [guessText, setGuessText] = useState("");

  useEffect(() => {
    if (feedback !== "correct") {
      return;
    }
    closeCurtains();
    setTimeout(() => {
      setFeedback(null);
      setTimeout(() => {
        chooseRandomMovie();
        openCurtains();
        setIsCaptive(false);
      }, 500);
    }, 1000);
  }, [feedback]);

  useEffect(() => {
    if (skipped !== true) {
      return;
    }
    closeCurtains();
    // Skipped is now true.
    setTimeout(() => {
      // After 1 second, set skipped to false.
      setSkipped(false);

      setTimeout(() => {
        // After another second, choose a random movie.
        chooseRandomMovie();
        openCurtains();
        setIsCaptive(false);
      }, 510);
    }, 1000);
  }, [skipped]);

  const shouldShowMovieTitle = skipped === true || feedback === "correct";

  let emojis;

  if (movie) {
    const emojiString = movie.emoji_puzzle;
    const splitter = new GraphemeSplitter();
    emojis = splitter.splitGraphemes(emojiString);
  }

  if (gameEnded) {
    return (
      <div>
        <h1>Game Over!</h1>
        <button onClick={quitGame}>Quit</button>
      </div>
    );
  }

  if (movie) {
    return (
      <div>
        <FeedbackContainer
          feedback={feedback}
          setFeedback={setFeedback}
          setIsCaptive={setIsCaptive}
        />
        <div className="guesserContainer">
          <EmojiDisplayer emojiString={movie.emoji_puzzle} />

          <div
            className="movieNameContainer"
            style={{
              opacity: shouldShowMovieTitle ? 1 : 0,
            }}
          >
            {movie.title} ({movie.year})
          </div>
          <p style={{ color: "white", textAlign: "center" }}>
            Lives: {"·".repeat(lives)} | Correct: {guessedCorrectly}
          </p>
          <MovieGuesserInput
            guessText={guessText}
            setGuessText={setGuessText}
            guessMovie={guessMovie}
            feedback={feedback}
          />

          <div className="buttonContainer">
            <button
              className="skipButton"
              onClick={skipMovie}
              disabled={isCaptive || lives == 1}
            >
              ⏩ Skip
            </button>
            <button
              className="guessButton"
              onClick={skipMovie}
              disabled={isCaptive || guessText.trim().length == 0}
            >
              💡 guess
            </button>
            <button
              className="quitButton"
              onClick={quitGame}
              disabled={isCaptive}
            >
              🚪 Quit
            </button>
          </div>
          <Hints hints={movie.hints} />
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Guesser;
