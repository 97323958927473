import "./Chairs.css";

const Chairs = () => {
  const numChairs = 50; // Set the desired number of chairs per row
  const numRows = 6; // Set the desired number of rows
  const baseWidth = 130; // Set the base width for the largest row
  const baseHeight = 65; // Set the base height for the largest row
  const sizeFactor = 0.9; // Set the size reduction factor for each row

  const generateChairStyle = (rowIndex) => {
    const sizePower = numRows - rowIndex - 1;
    const width = baseWidth * Math.pow(sizeFactor, sizePower);
    const height = baseHeight * Math.pow(sizeFactor, sizePower);
    return {
      width: `${width}px`,
      height: `${height}px`,
      borderTopRightRadius: `${width / 4}px`,
      borderTopLeftRadius: `${width / 4}px`,
    };
  };

  const generateRowStyle = (rowIndex) => {
    const sizePower = numRows - rowIndex - 1;
    const chairWidth = baseWidth * Math.pow(sizeFactor, sizePower);

    const prevPower = numRows - rowIndex;
    const prevChairWidth = baseWidth * Math.pow(sizeFactor, prevPower);

    const marginLeft =
      rowIndex % 2 === 0 ? -prevChairWidth / 4 : -chairWidth / 2;
    return {
      marginLeft: `${marginLeft}px`,
    };
  };

  const rowsData = Array.from({ length: numRows }, (_, index) => ({
    style: generateRowStyle(index),
  }));

  return (
    <div className="chairContainer">
      {rowsData.map((row, rowIndex) => (
        <div key={rowIndex} className="chairs" style={row.style}>
          {Array.from({ length: numChairs }, (_, chairIndex) => (
            <div
              key={chairIndex}
              className="chair"
              style={generateChairStyle(rowIndex)}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Chairs;
