import GraphemeSplitter from "grapheme-splitter";
import "./EmojiDisplayer.css";

const EmojiDisplayer = ({ emojiString }) => {
  const splitter = new GraphemeSplitter();
  const emojis = splitter.splitGraphemes(emojiString);

  return (
    <div className="stageRows">
      <div className="row">{emojis.slice(0, 3).join("")}</div>
      <div className="row">{emojis.slice(3, 6).join("")}</div>
      <div className="row">{emojis.slice(6, 9).join("")}</div>
    </div>
  );
};

export default EmojiDisplayer;
