import match from "./match.js";
import { useState, useEffect } from "react";

const useMovieHistory = (movies, historySize = 5) => {
  const [history, setHistory] = useState([]);

  const getMovieCandidate = () => {
    if (movies.length === 0) return null;

    let candidate;
    let attempts = 0;

    if (movies.length <= 5) {
      // If we have 5 or fewer movies, just choose any random movie
      const randIdx = Math.floor(Math.random() * movies.length);
      candidate = movies[randIdx];
    } else {
      // If we have more than 5 movies, check history
      do {
        const randIdx = Math.floor(Math.random() * movies.length);
        candidate = movies[randIdx];
        attempts++;
      } while (history.includes(candidate.title) && attempts < 3);
    }

    // Update history
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory, candidate.title];
      if (newHistory.length > historySize) newHistory.shift(); // Maintain the history size
      return newHistory;
    });

    return candidate;
  };

  const addMovieToHistory = (movieTitle) => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory, movieTitle];
      if (newHistory.length > historySize) newHistory.shift(); // Maintain the history size
      return newHistory;
    });
  };

  return { getMovieCandidate, addMovieToHistory };
};

const useMovieGuesser = (movies, initialLives) => {
  const [movie, setMovie] = useState(null);
  const [lives, setLives] = useState(initialLives);
  const [feedback, setFeedback] = useState(null); // State to manage feedback display
  const [guessedCorrectly, setGuessedCorrectly] = useState(0); // State to track correct guesses
  const [skipped, setSkipped] = useState(false); // State to track if a movie has been skipped
  const [isCaptive, setIsCaptive] = useState(false);

  const { getMovieCandidate, addMovieToHistory } = useMovieHistory(movies);

  const chooseRandomMovie = () => {
    setMovie(getMovieCandidate());
  };

  const guessMovie = (currentGuess) => {
    setSkipped(false);
    if (isCaptive) return;
    if (currentGuess.trim().length == 0) return;
    const currentMovieName = movie.title;
    const wasCorrect = match(currentMovieName, currentGuess.toLowerCase());
    if (wasCorrect) {
      setFeedback("correct");
      setGuessedCorrectly((prevCount) => prevCount + 1); // Increment correct guess count
    } else {
      setFeedback("incorrect");
      setLives((prevLives) => prevLives - 1);
    }
    console.log(currentMovieName, currentGuess, wasCorrect, movie.emoji_puzzle);
    setIsCaptive(true);
  };

  const skipMovie = () => {
    if (!movie) return;
    setIsCaptive(true);
    setLives((prevLives) => prevLives - 1);
    setSkipped(true);
    addMovieToHistory(movie.title);
  };

  useEffect(() => {
    chooseRandomMovie();
  }, [movies]);

  const gameEnded = lives <= 0;

  return {
    movie,
    guessMovie,
    lives,
    gameEnded,
    feedback,
    setFeedback, // Return setFeedback to allow resetting feedback after animation.
    chooseRandomMovie,
    guessedCorrectly, // Return correct guess count.
    skipMovie, // Return skipMovie function.
    setSkipped, // Return setSkipped to allow resetting after animation.
    skipped, // Return skipped state.
    isCaptive,
    setIsCaptive,
  };
};

export default useMovieGuesser;
