import React, { useEffect, useState } from "react";
import "./Hints.css"; // Assuming you have a CSS file for styles

const Hints = ({ hints }) => {
  const [expandedHints, setExpandedHints] = useState(
    Array(hints.length).fill(false),
  );

  const toggleHint = (index) => {
    const newExpandedHints = [...expandedHints];
    newExpandedHints[index] = !newExpandedHints[index];
    setExpandedHints(newExpandedHints);
  };

  useEffect(() => {
    setExpandedHints(Array(hints.length).fill(false));
  }, [hints]);

  return (
    <div className="hints">
      {hints.map((hint, index) => (
        <div key={index} className="hint">
          <div className="hint-header" onClick={() => toggleHint(index)}>
            Hint {index + 1}
            <span className="chevron">{expandedHints[index] ? "▲" : "▼"}</span>
          </div>
          <div
            className={`hint-content ${
              expandedHints[index] ? "expanded" : "collapsed"
            }`}
          >
            {hint}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hints;
