import React, { useState, useEffect } from "react";
import SelectScreen from "./SelectScreen";
import Guesser from "./Guesser";
import "./App.css";
import Stage from "./Stage";

import GameState from "./types/GameState";
import { GameStatus } from "./types/GameStatus";
import Movie from "./types/Movie";
import { CurtainProvider, useCurtain } from "./CurtainContext";

import Chairs from "./Chairs";

let initialState: GameState = {
  status: GameStatus.SelectScreen,
  movies: [],
  initialLives: 5,
};

function Switcher({ gameState, setMovies, startGame, quitGame }) {
  switch (gameState.status) {
    case GameStatus.MainMenu:
      return <div>Lol</div>;
    case GameStatus.SelectScreen:
      return (
        <div>
          <SelectScreen setMovies={setMovies} startGame={startGame} />
        </div>
      );
    case GameStatus.Playing:
      return (
        <Guesser
          movies={gameState.movies}
          initialLives={gameState.initialLives}
          quitGame={quitGame}
        ></Guesser>
      );
    default:
      return <div>Unknown State</div>;
  }
}

function App(): JSX.Element {
  const [gameState, setGameState] = useState<GameState>(null);
  const { openCurtains, closeCurtains } = useCurtain();

  useEffect(() => {
    setGameState(() => initialState);
  }, []);
  if (gameState == null) {
    return <div></div>;
  }

  const setMovies = (movies: Movie[]) => {
    setGameState((prevState) => ({
      ...prevState,
      movies,
    }));
  };

  const startGame = () => {
    setGameState((prevState) => {
      return {
        ...prevState,
        status: GameStatus.Playing,
      };
    });
    openCurtains();
  };

  const quitGame = () => {
    closeCurtains();

    setGameState((prevState) => {
      return {
        ...prevState,
        movies: [],
        status: GameStatus.SelectScreen,
      };
    });
  };

  return (
    <Switcher
      gameState={gameState}
      setMovies={setMovies}
      startGame={startGame}
      quitGame={quitGame}
    />
  );
}

const Root = () => {
  return (
    <div>
      <CurtainProvider>
        <Stage>
          <App />
        </Stage>
        <Chairs />
      </CurtainProvider>
    </div>
  );
};

export default Root;
