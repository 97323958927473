import { useState, useCallback } from "react";
import Movie from "../types/Movie";
import config from "../config";

const useFetchMovies = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchMovies = useCallback(
    async (genres: string[], decades: number[]): Promise<Movie[]> => {
      const data = { genres, decades };
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${config.apiBaseUrl}/movies`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          signal: controller.signal, // Attach the signal to the fetch request
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result;
      } catch (error) {
        if (error.name === "AbortError") {
          setError("Request timed out");
        } else {
          setError(error.message);
        }
        console.error("Failed to fetch movies:", error);
        return []; // Return an empty array in case of an error
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { fetchMovies, loading, error };
};

export default useFetchMovies;
